import React, { useState } from 'react';
import BlackEyeGalaxy from "../assets/img/BlackEyeGalaxy.png";
import BlueHorseHeadNebula from "../assets/img/BlueHorseHeadNebula.png";
import FS60C from "../assets/img/FS60C.png";
import LagoonNebula from "../assets/img/LagoonNebula.png";
import M3 from "../assets/img/M3.png";
import MolecularCloudComplexatAntares from "../assets/img/MolecularCloudComplexatAntares.png";
import OrionNebula from "../assets/img/OrionNebula.png";
import OrionNebulaRunningManNebaandHorseHeadNebula from "../assets/img/OrionNebulaRunningManNebaandHorseHeadNebula.png";
import PelicanNebula from "../assets/img/PelicanNebula.png";
import PillarsofCreation from "../assets/img/PillarsofCreation.png";
import PinwheelGalaxy from "../assets/img/PinwheelGalaxy.png";
import RingNebula from "../assets/img/RingNebula.png";
import RosetteNebula from "../assets/img/RosetteNebula.png";
import WitchHeadNebula from "../assets/img/WitchHeadNebula.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css"; // Import Lightbox CSS

export const Astrophotography = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showDescription, setShowDescription] = useState(true);

  const imageData = [
    {
      src: RosetteNebula,
      title: "Rosette Nebula",
      description: " "
    },
    {
      src: WitchHeadNebula,
      title: "Witch Head Nebula",
      description: " "
    },
    {
      src: BlackEyeGalaxy,
      title: "Black Eye Galaxy",
      description: " "
    },
    {
      src: BlueHorseHeadNebula,
      title: "Blue Horse Head Nebula",
      description: " "
    },
    {
      src: FS60C,
      title: "FS60C",
      description: " "
    },
    {
      src: LagoonNebula,
      title: "Lagoon Nebula",
      description: " "
    },
    {
      src: M3,
      title: "M3",
      description: " "
    },
    {
      src: MolecularCloudComplexatAntares,
      title: "Molecular Cloud Complex at Antares",
      description: " "
    },
    {
      src: OrionNebula,
      title: "Orion Nebula",
      description: " "
    },
    {
      src: OrionNebulaRunningManNebaandHorseHeadNebula,
      title: "Orion Nebula, Running Man Nebula and Horse Head Nebula",
      description: " "
    },
    {
      src: PelicanNebula,
      title: "Pelican Nebula",
      description: " "
    },
    {
      src: PillarsofCreation,
      title: "Pillars of Creation",
      description: " "
    },
    {
      src: PinwheelGalaxy,
      title: "Pinwheel Galaxy",
      description: " "
    },
    {
      src: RingNebula,
      title: "Ring Nebula",
      description: " "
    },

  ];
  

  const slides = imageData.map((data) => ({
    src: data.src,
    title: data.title,
    description: data.description
  }));

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  // Increase zoom level
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3));
  };

  // Decrease zoom level
  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 0.5));
  };

  // Toggle description visibility
  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    <section className="astrophotography" id="astrophotography">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="astrophotography-bx wow zoomIn">
              <h2>Astrophotography</h2>
              <Carousel 
                responsive={responsive} 
                infinite={true} 
                className="owl-carousel owl-theme astrophotography-slider"
              >
                {imageData.map((data, index) => (
                  <div className="item" key={index}>
                    <img
                      src={data.src}
                      alt={`Astro Image ${index + 1}`}
                      onClick={() => {
                        setPhotoIndex(index); 
                        setIsOpen(true);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                    <h5>{data.title}</h5>
                    <p>{data.description}</p>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />

      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={slides}
          index={photoIndex}
          on={{
            viewNext: () => setPhotoIndex((photoIndex + 1) % imageData.length),
            viewPrev: () => setPhotoIndex((photoIndex - 1 + imageData.length) % imageData.length),
          }}
          render={{
            slide: ({ slide }) => (
              <div style={{ position: 'relative', textAlign: 'center' }}>
                <img
                  src={slide.src}
                  alt={slide.title}
                  style={{
                    maxHeight: '80vh',
                    maxWidth: '100%',
                    transform: `scale(${zoomLevel})`,
                    transition: 'transform 0.3s ease'
                  }}
                />
                {showDescription && (
                  <div style={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    color: '#fff',
                    background: 'rgba(0, 0, 0, 0.5)',
                    padding: '10px',
                    borderRadius: '5px',
                    maxWidth: '80%',
                    textAlign: 'left'
                  }}>
                    <h3>{slide.title}</h3>
                    <p>{slide.description}</p>
                  </div>
                )}
              </div>
            ),
            toolbar: () => (
              <div style={{
                position: 'absolute',
                top: '10px',
                right: '20px',
                display: 'flex',
                gap: '10px'
              }}>
                <button
                  onClick={handleZoomIn}
                  style={{ background: 'rgba(0, 0, 0, 0.7)', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}
                >
                  Zoom In
                </button>
                <button
                  onClick={handleZoomOut}
                  style={{ background: 'rgba(0, 0, 0, 0.7)', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}
                >
                  Zoom Out
                </button>
                <button
                  onClick={toggleDescription}
                  style={{ background: 'rgba(0, 0, 0, 0.7)', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}
                >
                  {showDescription ? 'Hide Description' : 'Show Description'}
                </button>
                <button
                  onClick={() => setIsOpen(false)}
                  style={{ background: 'rgba(0, 0, 0, 0.7)', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}
                >
                  Close
                </button>
              </div>
            )
          }}
        />
      )}
    </section>
  );
};
