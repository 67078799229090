import React, { useState } from 'react';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

export const Projects = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showDescription, setShowDescription] = useState(true);

  const projects = [
    {
      title: "Latest",
      description: "",
      imgUrl: "https://allsky.uttampaudel.com/current/tmp/image.jpg", // Replace with your image URL
    },
    {
      title: "StarTrail",
      description: "",
      imgUrl: "https://allsky.uttampaudel.com/current/tmp/startrails.jpg", // Replace with your image URL
    },
    {
      title: "Rocket",
      description: "",
      imgUrl: "https://allsky.uttampaudel.com/current/tmp/rocket.jpg", // Replace with your image URL
    }
  ];

  const slides = projects.map((project) => ({
    src: project.imgUrl,
    title: project.title,
    description: project.description,
  }));

  // Toggle description visibility
  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>All Sky</h2>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Featured</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Live</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Other</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row className="justify-content-center">
                          {projects.map((project, index) => (
                            <Col key={index} md={4} className="mb-4">
                              <div className="project-img-container">
                                <img
                                  src={project.imgUrl}
                                  alt={project.title}
                                  className="project-img"
                                  onClick={() => {
                                    setPhotoIndex(index);
                                    setIsOpen(true);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="iframe-container">
                          <iframe
                            src="https://allsky.uttampaudel.com/allsky/" // Replace with the URL you want to embed
                            title="AllSky Live"
                            width="100%"
                            height="800px"
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="video-container">
                          <video
                            src="https://allsky.uttampaudel.com/current/tmp/timelaspe.mp4"
                            controls
                            style={{ width: '100%', maxHeight: '600px' }}
                          />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>

                  {isOpen && (
                    <Lightbox
                      open={isOpen}
                      close={() => setIsOpen(false)}
                      slides={slides}
                      index={photoIndex}
                      on={{
                        viewNext: () => setPhotoIndex((photoIndex + 1) % projects.length),
                        viewPrev: () => setPhotoIndex((photoIndex - 1 + projects.length) % projects.length),
                      }}
                      render={{
                        slide: ({ slide }) => (
                          <div style={{ position: 'relative', textAlign: 'center' }}>
                            <img
                              src={slide.src}
                              alt={slide.title}
                              style={{
                                maxHeight: '80vh',
                                maxWidth: '100%',
                              }}
                            />
                            {showDescription && (
                              <div style={{
                                position: 'absolute',
                                bottom: '20px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                color: '#fff',
                                background: 'rgba(0, 0, 0, 0.5)',
                                padding: '10px',
                                borderRadius: '5px',
                                maxWidth: '80%',
                                textAlign: 'left',
                              }}>
                                <h3>{slide.title}</h3>
                                <p>{slide.description}</p>
                              </div>
                            )}
                          </div>
                        ),
                        toolbar: () => (
                          <div style={{
                            position: 'absolute',
                            top: '10px',
                            right: '20px',
                            display: 'flex',
                            gap: '10px',
                          }}>
                            <button
                              onClick={toggleDescription}
                              style={{ background: 'rgba(0, 0, 0, 0.7)', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}
                            >
                              {showDescription ? 'Hide Description' : 'Show Description'}
                            </button>
                            <button
                              onClick={() => setIsOpen(false)}
                              style={{ background: 'rgba(0, 0, 0, 0.7)', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}
                            >
                              Close
                            </button>
                          </div>
                        ),
                      }}
                    />
                  )}
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background"></img>
    </section>
  );
};
